<template>
  <nav
    :class="[open ? 'h-full overflow-y-scroll' : 'transition-transform', scrolled]"
    :style="`transform: translateY(${scrolled || open ? 0 : 1.5}rem)`"
    class="fixed w-full z-40 duration-200 ease-in-out"
  >
    <div
      :class="[open ? 'px-6 bg-primary-dark' : 'container', {'bg-white text-grey' : !open && scrolled}, {'bg-primary-dark text-white' : open && scrolled}]"
      class="flex items-center justify-between flex-wrap rounded-b-40p transition delay-200 duration-200 ease-in"
    >
      <div class="flex items-center flex-shrink-0 mr-6">
        <nuxt-link :to="{name: 'index'}">
          <img v-show="!open && scrolled" :src="require('~/assets/img/logo-g.svg')" alt="Datalead" class="hidden lg:block transform scale-75">
          <img v-show="scrolled" :src="require('~/assets/img/logo-sub-g.svg')" alt="Datalead" class="w-40 lg:hidden">
          <img v-show="!scrolled" :src="require('~/assets/img/logo.svg')" alt="Datalead" class="hidden lg:block">
          <img v-show="!scrolled" :src="require('~/assets/img/logo-sub.svg')" alt="Datalead" class="w-40 lg:hidden">
        </nuxt-link>
      </div>
      <div
        :class="scrolled ? 'text-grey' : 'text-white'"
        class="hidden lg:block font-link ml-12"
      >
        Advies nodig? Mail ons:
        <a :href="`mailto:${settings.email}`" target="_blank" rel="noopener" class="text-secondary no-underline pl-1">
          <font-awesome-icon :icon="['fas', 'envelope']" size="sm"/>
          {{ settings.email }}
        </a>
      </div>
      <div
        :class="!open && scrolled ? 'text-grey' : 'text-white'"
        class="lg:hidden"
      >
        <button class="flex focus:outline-none items-center py-2" @click="toggle">
          <svg class="fill-current h-8 w-8" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>

      <transition name="slide-down">
        <div v-if="open" class="w-screen sm:w-101v min-h-screen bg-primary-dark -mx-6 pt-3 px-6 lg:hidden z-50">
          <div class="text-white font-link">
            Advies nodig? Mail ons:
            <a :href="`mailto:${settings.email}`" target="_blank" rel="noopener" class="text-secondary no-underline pl-1">
              <font-awesome-icon :icon="['fas', 'envelope']" size="sm"/>
              {{ settings.email }}
            </a>
          </div>
          <ul class="nav-list">
            <li v-for="link in mainMenu" :key="link.title" class="text-sm lg:flex-grow">
              <nuxt-link
                :to="link.children ? '' : link.url || ''"
                class="block border border-white text-white text-center no-underline py-3 my-4"
                @click.native="toggle"
              >
                {{ link.text }}
                <font-awesome-icon v-if="link.icon" :icon="['fas', 'caret-down']"/>
              </nuxt-link>
              <ul v-if="link.children" class="nav-list">
                <li v-for="(child, index) in link.children" :key="child.url" class="pb-2 text-center">
                  <nuxt-link :to="child.url" class="no-underline font-body text-xs text-white" @click.native="toggle">
                    {{ child.text }}
                  </nuxt-link>
                  <hr v-if="index !== link.children.length - 1" class="mt-2 text-gray-lighter">
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </transition>

      <div class="w-full hidden lg:flex lg:flex-grow lg:items-center lg:w-auto">
        <ul class="nav-list text-sm lg:flex-grow lg:text-right">
          <li
            v-for="link in mainMenu"
            :key="link.text"
            class="relative group mt-4 lg:inline-block lg:mt-0 ml-8 py-6"
          >
            <nuxt-link
              :to="link.children ? '' : link.url || ''"
              :class="{'text-grey':scrolled}"
              class="hover:text-secondary focus:text-secondary nav"
            >
              {{ link.text }}
              <font-awesome-icon v-if="link.children" :icon="['fas', 'caret-down']"/>
            </nuxt-link>
            <ul
              v-if="link.children"
              class="nav-list hidden group-hover:block cursor-auto absolute right-0 rounded shadow-xl bg-white pt-5 pb-4 -mr-5 top-100 lg:w-56"
            >
              <li
                v-for="(child, index) in link.children"
                :key="child.url"
                class="nav__icon relative hover:cursor-pointer left-0 right-0 bg-white text-left pb-2 px-6"
              >
                <nuxt-link
                  :to="child.url"
                  class="flex no-underline font-body text-xs w-full text-gray hover:text-secondary"
                >
                  {{ child.text }}
                </nuxt-link>
                <hr v-if="index !== link.children.length - 1" class="mt-2 text-gray-lighter">
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      open: false,
      scrolled: false,
    };
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      mainMenu: state => state.mainMenu,
    }),
  },
  mounted() {
    this.scrolled = window.scrollY > 50;
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    onScroll() {
      this.scrolled = window.scrollY > 50;
    },
  },
};
</script>

<style lang="scss" scoped>
.delay-200 {
  transition-delay: 200ms;
}

ul > .nav__icon:first-of-type::before {
  position: absolute;
  content: '';
  top: -32px;
  right: 12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 14px solid white;
}

.fade-enter-active, .fade-leave-active {
  transition: all .1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-down-enter-active {
  transition: transform .5s;
}
.slide-down-leave-active {
  transition: transform .1s;
}
.slide-down-enter, .slide-down-leave-to {
  transform: translateY(100vh);
}
</style>
