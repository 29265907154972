export const state = () => ({
  release: process.env.RELEASE,
  page: {},
  products: [],
  testimonials: [],
  usps: [],
  settings: {},
  mainMenu: [],
  footerMenu: [],
  legalMenu: [],
  testimonialsCount: 0,
  contact: {},
  contacts: [],
});

export const actions = {
  async nuxtServerInit({commit}) {
    const [products, testimonials, usps, settings, menus] = await Promise.all([
      this.$axios.get('/products'),
      this.$axios.get('/testimonials'),
      this.$axios.get('/usps'),
      this.$axios.get('/settings'),
      this.$axios.get('/menus'),
    ]);

    commit('SET_PRODUCTS', products.data.data);
    commit('SET_TESTIMONIALS', testimonials.data);
    commit('SET_USPS', usps.data.data);
    commit('SET_SETTINGS', settings.data);
    commit('SET_MAIN_MENU', menus.data.main);
    commit('SET_FOOTER_MENU', menus.data.footer);
    commit('SET_LEGAL_MENU', menus.data.legal);
  },
  async getPage({commit}, slug = '') {
    const {data} = await this.$axios.get(`/page?segments=${slug}`);

    commit('SET_PAGE', data.data);
  },

  async setContact({commit, state}) {
    if (state.contacts.length < 1) {
      const {data} = await this.$axios.get('/contacts');
      commit('SET_CONTACTS', data.data);
    }

    commit('SET_RANDOM_CONTACT');
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_TESTIMONIALS(state, payload) {
    state.testimonials = payload.data;
    state.testimonialsCount = payload.count;
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_USPS(state, payload) {
    state.usps = payload;
  },
  SET_MAIN_MENU(state, payload) {
    state.mainMenu = payload;
  },
  SET_FOOTER_MENU(state, payload) {
    state.footerMenu = payload;
  },
  SET_LEGAL_MENU(state, payload) {
    state.legalMenu = payload;
  },
  SET_RANDOM_CONTACT(state) {
    state.contact = state.contacts[Math.floor(Math.random() * state.contacts.length)];
  },
  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },
};
