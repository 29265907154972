<template>
  <div>
    <modal>
      <div class="text-center">
        <p class="mb-6">Beste bezoeker,</p>
        <p class="mb-4">Dank voor je bezoek aan onze website! Wegens groot succes zijn we momenteel niet in staat om nieuwe aanvragen voor integraties snel op te volgen. Excuus voor het ongemak.</p>
        <p class="mb-3">Ben jij als developer opzoek naar een nieuwe uitdaging of ken je een developer?</p>
        <p class="mb-4">
          <a
            href="https://werkenbij.bttr.nl/back-end-developer/nl"
            target="_blank"
            rel="noopener"
            class="btn-wrapper relative text-xl inline-flex justify-center items-center no-underline hover:text-white border-2 border-secondary rounded-lg py-2 px-6 overflow-hidden transition duration-300"
          >
            <span class="btn-background absolute top-0 bottom-0 bg-secondary transition-all duration-300"/>
            <span class="relative">
              Bekijk de vacature
              <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2"/>
            </span>
          </a>
        </p>
        <p class="mb-4">Vriendelijke groet,</p>
        <p>Datalead</p>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '~/components/Modal';

export default {
  components: {
    Modal,
  },
};
</script>

<style scoped>
.btn-wrapper .btn-background {
  right: 110%;
  transform: skew(-20deg);
  left: -10%;
}
.btn-wrapper:hover .btn-background {
  right: -10%;
}
</style>
