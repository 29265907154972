<template>
  <div class="read error-bg bg-no-repeat bg-center bg-cover py-24 md:pt-40 md:pb-96">
    <section class="flex flex-col justify-center items-center">
      <img
        :src="require('~/assets/img/error.svg')"
        alt="It's a match!"
        class="pt-8"
      >
      <template v-if="error.statusCode === 410 || error.statusCode === 404">
        <h2 class="text-white text-lg" v-text="page.kop_2"/>
        <!--eslint-disable-next-line-->
        <div class="w-full sm:w-3/4 md:w-1/3 text-white text-center leading-loose pt-2 pb-10 px-6 sm:px-0">
          <div v-html="page.tekst_2"/>
          <div class="mt-8" v-html="page.tekst_3"/>
        </div>
        <nuxt-link :to="{name: 'index'}" class="flex items-center no-underline hover:text-white">
          <span class="btn-round mr-2">
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']"/>
          </span>
          Klikklikklik
        </nuxt-link>
      </template>
      <h1 v-else>
        Er is een fout opgetreden
      </h1>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.error-bg
  background-image: url("~assets/img/bg-match.svg")
</style>
