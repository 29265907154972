import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faArrowRight,
  faCaretDown,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSearch,
  faVolumeUp,
  faTimes,
  faEnvelope,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faLink,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faInstagram,
  faLinkedinIn,
  faTelegramPlane,
  faTwitter,
  faFacebookF,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowRight,
  faCaretDown,
  faFacebookF,
  faInstagram,
  faLink,
  faLinkedinIn,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSearch,
  faTelegramPlane,
  faTimes,
  faTwitter,
  faVolumeUp,
  faYoutube,
  faEnvelope,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
