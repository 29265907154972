<template>
  <div id="readspeaker_button1" class="rs_skip rsbtn rs_preserve">
    <a rel="nofollow" class="rsbtn_play" accesskey="L" title="Laat de tekst voorlezen met ReadSpeaker webReader" :href="url">
      <span class="rsbtn_left rsimg rspart"><span class="rsbtn_text"><span>Lees voor</span></span></span>
      <!--      <span class="hidden rsbtn_right rsimg rsplay rspart"/>-->
    </a>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-white',
    },
  },
  computed: {
    url() {
      return `https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=11370&amp;lang=nl_nl&amp;readid=read&amp;url=${encodeURI(process.env.baseUrl + this.$route.fullPath)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rsbtn_play,
::v-deep .rsbtn_exp_inner,
::v-deep .rsbtn_player_item {
  border: none;
  background: transparent;

  &:hover,
  &:focus {
    background: transparent;
    color: theme('colors.secondary.default') !important;
  }
}

::v-deep .rsbtn_exp_inner {
  color: white;
}

::v-deep .rs-player-loadindicator {
  background: white;
}

::v-deep .rsbtn_text,
::v-deep .rsbtn_text span {
// ::v-deep .rsbtn_right {
  color: white !important;
}

::v-deep .rsbtn_left {
  margin-left: 0 !important;
}

// ::v-deep .rsbtn_right,
::v-deep .rsbtn_player_item {
  border-left: 1px solid white !important;
  &.rsimg {
    padding: 0 0 0 7px !important;
  }
}

::v-deep .rsbtn_play:hover .rsbtn_text,
::v-deep .rsbtn_play:hover span {
// ::v-deep .rsbtn_play:hover .rsbtn_right::before {
  color: theme('colors.secondary.default') !important;
}
</style>
