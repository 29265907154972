<template>
  <div>
    <navigation/>
    <nuxt id="read"/>
    <app-footer/>
    <!--<pop-up v-if="!noShowPopUp"/>-->
  </div>
</template>

<script>
import AppFooter from '~/layouts/partials/AppFooter';
import Navigation from '~/layouts/partials/Navigation';
// import PopUp from '~/layouts/partials/PopUp';

export default {
  components: {
    AppFooter,
    Navigation,
    // PopUp,
  },
  /* computed: {
    noShowPopUp() {
      return this.$route.path === '/' ||
        this.$route.path === '/home' ||
        this.$route.path.includes('tribecrm') ||
        this.$route.path.includes('perfectview') ||
        this.$route.path.includes('snelstart');
    },
  }, */
};
</script>
