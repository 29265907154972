<template>
  <div>
    <form method="post" class="text-black flex flex-col pt-5 w-full md:w-6/12" @submit.prevent="submit">
      <div v-for="field in template.fields" :key="field.name">
        <span v-if="errors[field.name]" class="italic">{{ errors[field.name][0] }}</span>
        <textarea
          v-if="field.type === 'textarea'"
          v-model="form[field.name]"
          :aria-label="field.label"
          :placeholder="label(field)"
          class="input h-32 placeholder-black focus:placeholder-secondary"
        />
        <input
          v-else
          v-model="form[field.name]"
          :type="field.type"
          :aria-label="field.label"
          :placeholder="label(field)"
          class="input placeholder-black opacity-100 focus:placeholder-secondary"
        >
      </div>
      <button class="btn w-6/12 md:w-8/12 xl:w-6/12 self-center sm:self-end mt-2" @click="submit">
        <span class="btn__text py-3">Verstuur</span>
        <span class="btn__icon py-3">
          <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']"/>
        </span>
      </button>
    </form>
    <transition name="fade">
      <div v-if="success" class="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center">
        <div
          class="bg-primary-dark flex flex-col justify-center items-center relative bg-blue h-48 w-80 rounded-lg shadow-2xl"
          role="alert"
        >
          <h3>Uw bericht is verstuurd!</h3>
          <button class="btn mt-4" @click="success = false">
            <span class="btn__text py-3 px-5">Oke</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import commonForm from '~/plugins/mixins/form';

export default {
  mixins: [commonForm],
  props: {
    id: {
      type: Number,
      default: 1,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: false,
      template: {},
      form: {},
    };
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;

    // Set defaults
    const that = this;
    this.template.fields.forEach((field) => {
      if (field.type === 'checkbox') {
        // Default must be array to work
        that.form[field.name] = [];
      }
    });
  },
  methods: {
    onError(error) {
      console.log('Error happened:', error);
      // todo: handling
    },
    onSuccess(token) {
      console.log('Succeeded:', token);
      // todo: handling
    },
    onExpired() {
      console.log('Expired');
      // todo: handling
    },
    async submit() {
      try {
        await this.$axios.post('/forms/' + this.id, {...this.form});
        this.success = true;
        this.form = {};
      } catch (e) {
        console.log(e);
      }
    },
    label(field) {
      if (field.required) {
        return `${field.label}*`;
      }

      return field.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
