<template>
  <footer class="container mx-0 px-0 sm:mx-auto lg:px-6 text-center">
    <div v-if="clients" class="py-20 lg:px-32">
      <ul class="flex flex-wrap items-center justify-between nav-list w-full overflow-hidden">
        <li v-for="client in clients.slice(start, end)" :key="client.title" class="w-1/2 sm:w-1/4 md:w-1/6 flex justify-center">
          <img :src="client.image" :alt="client.title" class="w-20 h-20 object-contain grey-logo" style="filter: grayscale(100%)">
        </li>
      </ul>
    </div>
    <div class="bg-cover bg-center sm:rounded-lg text-white" :style="`background-image: url('${require('~/assets/img/bg-footer.svg')}');`">
      <div class="flex justify-center py-8">
        <img :src="require('~/assets/img/logo-sub.svg')" alt="Datalead" class="hidden lg:block">
        <img :src="require('~/assets/img/logo.svg')" alt="Datalead" class="w-20 h-20 lg:hidden">
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-3 text-left px-10 lg:ml-40 lg:mr-20 pb-16">
        <div v-for="group in footerMenu" :key="group.text" class="tracking-wide pr-4">
          <h5 class="font-strong">{{ group.text }}</h5>
          <div v-for="child in group.children" :key="child.text" class="my-3">
            <nuxt-link :to="child.url" class="nav leading-normal font-body hover:text-secondary">
              {{ child.text }}
            </nuxt-link>
          </div>
        </div>
        <div class="leading-9 tracking-wide pr-4">
          <div class="font-strong">{{ settings.name }}</div>
          <div class="leading-relaxed">
            <div>{{ settings.address }}</div>
            <div>{{ settings.postalcode }} {{ settings.city }}</div>
            <a
              :href="`mailto:${settings.email}`"
              target="_blank"
              rel="noopener"
              class="font-body no-underline text-white hover:text-secondary pt-8"
            >
              <span class="font-semi">Mail:</span> {{ settings.email }}
            </a>
            <a
              v-if="!!settings.phone"
              :href="`tel:${settings.phone}`"
              target="_blank"
              rel="noopener"
              class="block font-body no-underline text-white hover:text-secondary"
            >
              <span class="font-semi">Tel:</span> {{ settings.phone }}
            </a>

            <a
              v-for="(link, index) in legalMenu"
              :key="link.url"
              :href="link.url"
              target="_blank"
              rel="noopener"
              class="block font-body no-underline text-white hover:text-secondary"
              :class="index === 0 ? 'pt-8' : ''"
            >
              {{ link.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="text-sm my-4">
      Datalead is onderdeel van
      <a href="https://bttr.nl" target="_blank" rel="noopener">
        <img :src="require('~/assets/img/bttr-logo.svg')" alt="BTTR" class="inline ml-2 hover:opacity-75">
      </a>
    </div>
  </footer>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      clients: null,
      start: 0,
      end: 6,
      slideSize: 6,
    };
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      footerMenu: state => state.footerMenu,
      legalMenu: state => state.legalMenu,
    }),
  },
  watch: {
    clients: {
      deep: true,
      handler() {
        this.startSlider();
      },
    },
  },
  created() {
    this.getClients();
  },
  methods: {
    startSlider() {
      if (this.clients.length > this.slideSize) {
        setInterval(() => {
          if ((this.end + this.slideSize) < (this.clients.length + this.slideSize - 1)) {
            this.start = this.end;
            this.end = this.end + this.slideSize;
          } else {
            this.start = 0;
            this.end = this.slideSize;
          }
        }, 4000);
      }
    },
    async getClients() {
      const {data} = await this.$axios.get('customers');
      this.clients = data.data;
    },
  },
};
</script>
