<template>
  <transition
    enter-active-class="transition ease-out duration-300 transform"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-show="visible" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-stretch justify-center min-h-screen text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div
            class="absolute inset-0 bg-grey opacity-75"
            @click="visible = false"
          />
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"/>
        <transition
          enter-active-class="transition ease-out duration-300 transform"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="transition ease-in duration-200 transform"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            v-show="visible"
            class="relative inline-block align-bottom bg-white md:rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-sm lg:max-w-screen-lg w-full py-20 px-20"
            role="dialog"
            aria-visible="true"
          >
            <span
              class="absolute top-0 right-0 mt-8 mr-8"
              @click="visible = false"
            >
              <font-awesome-icon :icon="['fas', 'times']" size="2x"/>
            </span>
            <slot name="header"/>
            <slot/>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
