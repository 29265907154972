export default ({$axios, store, app}) => {
  $axios.onError((error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          app.$bugsnag.notify(error);
          console.error(error.response.data.error || 'Je hebt niet de juiste rechten om deze actie uit te voeren.');

          break;

        case 422:
          store.dispatch('validation/setErrors', error.response.data.errors);
          console.error('Niet alle velden zijn correct ingevuld.');

          break;

        default:
          console.error('Er is een fout opgetreden.');
      }
    }

    return Promise.reject(error);
  });

  $axios.onRequest(() => {
    store.dispatch('validation/clearErrors');
  });
};
