export const state = () => ({
  questions: [],
});

export const actions = {
  async getQuestions({commit}, q = '') {
    const {data} = await this.$axios.get('faqs', {params: {q}});

    commit('SET_QUESTIONS', data.data);
  },
};

export const mutations = {
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
};
