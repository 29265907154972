import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f97bcfc = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _12a9defb = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _3c12ddc8 = () => interopDefault(import('../pages/koppeling/index.vue' /* webpackChunkName: "pages/koppeling/index" */))
const _708f2245 = () => interopDefault(import('../pages/koppelingen/index.vue' /* webpackChunkName: "pages/koppelingen/index" */))
const _40ef9e40 = () => interopDefault(import('../pages/sectoren/index.vue' /* webpackChunkName: "pages/sectoren/index" */))
const _10f33db3 = () => interopDefault(import('../pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _3f802058 = () => interopDefault(import('../pages/koppeling/_slug.vue' /* webpackChunkName: "pages/koppeling/_slug" */))
const _88ca1c26 = () => interopDefault(import('../pages/koppelingen/_id.vue' /* webpackChunkName: "pages/koppelingen/_id" */))
const _71a62970 = () => interopDefault(import('../pages/sectoren/_id.vue' /* webpackChunkName: "pages/sectoren/_id" */))
const _2feea7b4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _523b6fe8 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _3f97bcfc,
    name: "contact"
  }, {
    path: "/faq",
    component: _12a9defb,
    name: "faq"
  }, {
    path: "/koppeling",
    component: _3c12ddc8,
    name: "koppeling"
  }, {
    path: "/koppelingen",
    component: _708f2245,
    name: "koppelingen"
  }, {
    path: "/sectoren",
    component: _40ef9e40,
    name: "sectoren"
  }, {
    path: "/faq/:slug",
    component: _10f33db3,
    name: "faq-slug"
  }, {
    path: "/koppeling/:slug",
    component: _3f802058,
    name: "koppeling-slug"
  }, {
    path: "/koppelingen/:id",
    component: _88ca1c26,
    name: "koppelingen-id"
  }, {
    path: "/sectoren/:id",
    component: _71a62970,
    name: "sectoren-id"
  }, {
    path: "/",
    component: _2feea7b4,
    name: "index"
  }, {
    path: "/*",
    component: _523b6fe8,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
